
import {
  ComponentPublicInstance,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "SignIn",
  components: {
    FormSubmitButton,
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const state = reactive({
      loginString: "",
      loading: false,
    });

    const loginForm = ref<{ setFieldValue } | null>(null);
    const submitButton = ref<ComponentPublicInstance | null>(null);

    const validationSchema = Yup.object().shape({
      email: Yup.string().required().label("Login"),
      password: Yup.string().optional().label("Password"),
    });

    const handleKeyEvent = (e) => {
      if (
        e.target == null ||
        ["INPUT", "TEXTAREA"].indexOf(e.target?.nodeName) === -1
      ) {
        if (e.keyCode == 13) {
          loginForm.value?.setFieldValue("email", state.loginString);
          submitButton.value?.$el.click();
          state.loginString = "";
        } else {
          state.loginString = state.loginString.concat(e.key);
        }
      }
    };

    const store = useStore();

    const router = useRouter();

    const onSubmit = (values, { resetForm }) => {
      state.loading = true;
      ApiService.post("auth/login", { ...values, device_name: "admin-panel" })
        .then(({ data }) => {
          store.dispatch(Actions.SET_AUTH, data).then(() => {
            router.back();
          });
        })
        .catch((error) => {
          Notify.apiError(error);
          resetForm();
        })
        .finally(() => {
          state.loading = false;
        });
    };

    // Zebra Support
    onMounted(() => {
      window.addEventListener("keypress", handleKeyEvent);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("keypress", handleKeyEvent);
    });

    return {
      state,
      validationSchema,
      loginForm,
      submitButton,
      store,
      onSubmit,
    };
  },
});
