<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      id="kt_login_signin_form"
      ref="loginForm"
      class="form w-100"
      :validation-schema="validationSchema"
      @submit="onSubmit"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">Sign In to Storm 2.0</h1>
        <!--end::Title-->
      </div>
      <!--begin::Heading-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fs-6 fw-bolder text-dark">
          Email / PIN / Token
        </label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          ref="emailField"
          class="form-control form-control-lg form-control-solid"
          type="text"
          name="email"
          autocomplete="email"
          placeholder="Your login..."
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="email" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack mb-2">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6 mb-0">
            Password
          </label>
          <!--end::Label-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          name="password"
          autocomplete="off"
          placeholder="Your password..."
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="password" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->
        <form-submit-button
          ref="submitButton"
          class="w-100 btn-primary"
          :loading="state.loading"
        >
          Continue
        </form-submit-button>
        <!--end::Submit button-->
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import {
  ComponentPublicInstance,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import FormSubmitButton from "@/components/forms/buttons/FormSubmitButton.vue";
import ApiService from "@/core/services/ApiService";
import Notify from "@/modules/common/helpers/Notify";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "SignIn",
  components: {
    FormSubmitButton,
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const state = reactive({
      loginString: "",
      loading: false,
    });

    const loginForm = ref<{ setFieldValue } | null>(null);
    const submitButton = ref<ComponentPublicInstance | null>(null);

    const validationSchema = Yup.object().shape({
      email: Yup.string().required().label("Login"),
      password: Yup.string().optional().label("Password"),
    });

    const handleKeyEvent = (e) => {
      if (
        e.target == null ||
        ["INPUT", "TEXTAREA"].indexOf(e.target?.nodeName) === -1
      ) {
        if (e.keyCode == 13) {
          loginForm.value?.setFieldValue("email", state.loginString);
          submitButton.value?.$el.click();
          state.loginString = "";
        } else {
          state.loginString = state.loginString.concat(e.key);
        }
      }
    };

    const store = useStore();

    const router = useRouter();

    const onSubmit = (values, { resetForm }) => {
      state.loading = true;
      ApiService.post("auth/login", { ...values, device_name: "admin-panel" })
        .then(({ data }) => {
          store.dispatch(Actions.SET_AUTH, data).then(() => {
            router.back();
          });
        })
        .catch((error) => {
          Notify.apiError(error);
          resetForm();
        })
        .finally(() => {
          state.loading = false;
        });
    };

    // Zebra Support
    onMounted(() => {
      window.addEventListener("keypress", handleKeyEvent);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("keypress", handleKeyEvent);
    });

    return {
      state,
      validationSchema,
      loginForm,
      submitButton,
      store,
      onSubmit,
    };
  },
});
</script>
